.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  border: 2px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
