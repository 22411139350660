.CategoryHeadingRow {
  margin: 0;
  font-size: x-large;
  text-transform: uppercase;
}

.CategoryCheckDiv {
  width: 100%;
  padding: 0 10px;
}

.CategoryCheckBox {
  margin: 12px -25px;
}

.flex-column {
  width: 100%;
}
